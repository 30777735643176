* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'px grotesk';
}

@font-face {
  font-family: 'px grotesk';
  src: url('./fonts/px_grotesk_light.ttf');
  font-style: normal;
}

@font-face {
  font-family: 'basier';
  src: url('./fonts/basier.ttf');
  font-style: normal;
}

@font-face {
  font-family: 'bogam';
  src: url('./fonts/bogam.otf');
  font-style: normal;
}

@font-face {
  font-family: 'chaney';
  src: url('./fonts/chaney.ttf');
  font-style: normal;
}

@font-face {
  font-family: 'frac';
  src: url('./fonts/frac.ttf');
  font-style: normal;
}

@font-face {
  font-family: 'migra';
  src: url('./fonts/migra.ttf');
  font-style: normal;
}

@font-face {
  font-family: 'pluto';
  src: url('./fonts/pluto.otf');
  font-style: normal;
}

@font-face {
  font-family: 'trash';
  src: url('./fonts/trash.otf');
  font-style: normal;
}

body {
  overflow: hidden;
}

button {
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
}

*::-webkit-scrollbar {
  display: none;
}

[contenteditable]:empty:before {
  content: attr(placeholder);
}

:not(#name)[contenteditable]:empty:before {
  opacity: 0.4;
}

#name[contenteditable]:empty:before {
  color: var(--grey4);
}

[contenteditable] {
  caret-color: deeppink;
}

*[contenteditable]:focus {
  text-transform: none;
}

::selection {
  background: aquamarine;
  color: deeppink;
}

.gradient {
  width: 100%;
  height: 100px;
  position: fixed;
  pointer-events: none;
}

.gradient.to-top {
  background: linear-gradient(to top, transparent, white);
}

.gradient.to-bottom {
  background: linear-gradient(to bottom, transparent, white);
}

.hover_blend_mode_difference:hover {
  mix-blend-mode: difference;
}

.image-loading {
  animation: 1s fade-in;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#search-modal {
  box-shadow: 0 0 60px lime;
}

#search-modal input {
  color: lime;
}

#search-modal ::selection {
  background: var(--grey7);
  color: dodgerblue;
}

.text-overflow-gradient {
  top: 153px;
  width: 100%;
  height: 250px;
  position: fixed;
  animation: 0.3s fade-in;
  pointer-events: none;
  background: linear-gradient(var(--grey2), transparent);
}
